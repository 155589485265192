(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tur/bubble-info/assets/javascripts/components/bubble-info.js') >= 0) return;  svs.modules.push('/components/tur/bubble-info/assets/javascripts/components/bubble-info.js');
"use strict";


setGlobal('svs.tur.bubbleInfo.components.BubbleInfo', React.forwardRef((_ref, ref) => {
  let {
    animateHeight,
    header,
    className = '',
    children
  } = _ref;
  return React.createElement("div", {
    className: "".concat(className, " ").concat(animateHeight ? 'animate-height' : 'animate-wiggle', " bubble-info-message elevation-1200-filter tiny-tail"),
    ref: ref
  }, React.createElement("div", {
    className: "bubble-info-big-header bubble-info-header"
  }, header), React.createElement("div", {
    className: "bubble-info-message-wrapper"
  }, React.createElement("div", {
    className: "bubble-info-message-description"
  }, React.Children.map(children, child => child))));
}));

 })(window);